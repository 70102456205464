<template
  ><div class="scale">
    <span v-for="(item, index) in 11" :key="item">
      <div class="input-radio">
        <input
          name="value"
          :value="index"
          v-model="data.value"
          required
          type="radio"
          :id="index"
        />
        <span class="checkmark"></span>
      </div>
      <label :for="index">{{ index }}</label>
    </span>
  </div>
</template>

<script>
export default {
  name: "Scale",
  data: () => {
    return {
      data: {
        value: -1
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    getAnswer() {
      return {
        questionId: this.questionId,
        optionValue: this.data.value,
        optionId: false
      };
    }
  }
};
</script>

<style scoped></style>
